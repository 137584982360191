<script>
export default {
  name: "NumberFormatter",

  methods: {
    isTruncated(value) {
      return value >= 100000
    },
    formatClicksImpressions(value) {
      if (!value && value !== 0) return ""
      let suffix = ""
      if (value >= 1000000) {
        value = value / 1000000
        if (value % 1 !== 0) value = value.toFixed(2)
        suffix = "M"
      } else if (value >= 100000) {
        value = value / 1000
        if (value % 1 !== 0) value = value.toFixed(2)
        suffix = "K"
      } else {
        value = Math.floor(value).toLocaleString()
      }

      return { value, suffix }
    },
    formatCtr(value) {
      return value.toFixed(2)
    },
  },
}
</script>
