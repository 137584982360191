<template>
  <div class="sparkline-container">
    <highcharts :options="options" ref="chart"></highcharts>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "TrackerSparkLine",

  mixins: [],
  components: {},

  props: {
    clicks: {
      required: true,
    },
    impressions: {
      required: true,
    },
    conversions: {
      required: true,
    },
    ctr: {
      required: true,
    },
    cr: {
      required: true,
    },
    metric: {
      require: true,
    },
    scale: {
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    options() {
      return {
        chart: {
          height: "20%",
          margin: [2, 2, 2, 2],
          backgroundColor: null,
          borderWidth: 0,
          style: {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            fontColor: "#303133",
          },
        },

        credits: {
          enabled: false,
        },

        legend: {
          enabled: false,
        },

        plotOptions: {
          line: {
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
                select: {
                  enabled: false,
                },
              },
            },
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },

        series: [
          {
            type: "line",
            color: this.lineColor,
            id: "data",
            yAxis: "data",
            data: this.data,
          },
        ],

        title: {
          text: null,
        },

        tooltip: {
          shared: true,
          enabled: false,
        },

        xAxis: {
          labels: {
            enabled: false,
          },
          tickLength: 0,
          visible: false,
        },

        yAxis: [
          {
            id: "data",
            title: {
              text: null,
            },
            visible: false,
          },
        ],
      }
    },
    lineColor() {
      if (this.metric === "clicks") {
        return "#67c23a"
      } else if (this.metric === "impressions") {
        return "#409eff"
      } else if (this.metric === "conversions") {
        return "#f56c6c"
      } else if (this.metric === "cr") {
        return "#9932cc"
      } else {
        return "#e6a23c"
      }
    },
    data() {
      return this[this.metric]
    },
  },
  watch: {},

  methods: {},

  created() {},
  mounted() {},
}
</script>
