<template>
  <el-card class="tracker-graph unselectable">
    <div class="tracker-graph-container">
      <div class="graph-container">
        <div class="graph">
          <div class="no-data" :class="{ visible: noData }">
            <div style="padding: 40px;">There is no data for the selected date range.</div>
          </div>
          <highcharts :options="options" ref="chart"></highcharts>
        </div>
        <span class="currentDate" ref="currentDateContainer">{{
          currentDate
        }}</span>
      </div>
      <div class="stats-container">
        <div class="stats" v-if="type === 'video' || type === 'tv'">
          <div
            class="impressions"
            :class="{ active: activeData.indexOf('impressions') !== -1 }"
            @click="toggleData('impressions')"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="formattedClicksImpressions(currentImpressions, true)"
              placement="bottom"
              :disabled="!isTruncated(currentImpressions)"
            >
              <div
                class="number"
                v-html="formattedClicksImpressions(currentImpressions)"
              ></div>
            </el-tooltip>
            <div class="label text-info">Views</div>
          </div>
          <div
            class="conversions"
            :class="{ active: activeData.indexOf('conversions') !== -1 }"
            @click="toggleData('conversions')"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="formattedClicksImpressions(currentConversions, true)"
              placement="bottom"
              :disabled="!isTruncated(currentConversions)"
            >
              <div
                class="number"
                v-html="formattedClicksImpressions(currentConversions)"
              ></div>
            </el-tooltip>
            <div class="label text-danger text-truncate">Completed</div>
          </div>
          <div
            class="cr"
            :class="{ active: activeData.indexOf('cr') !== -1 }"
            @click="toggleData('cr')"
          >
            <div class="number" v-html="formattedCtr(currentCr)"></div>
            <div class="label text-purple">Complete Rate</div>
          </div>
        </div>
        <div class="stats" v-else>
          <div
            class="clicks"
            :class="{ active: activeData.indexOf('clicks') !== -1 }"
            @click="toggleData('clicks')"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="formattedClicksImpressions(currentClicks, true)"
              placement="bottom"
              :disabled="!isTruncated(currentClicks)"
            >
              <div
                class="number"
                v-html="formattedClicksImpressions(currentClicks)"
              ></div>
            </el-tooltip>
            <div class="label text-success">Clicks</div>
          </div>
          <div
            class="impressions"
            :class="{ active: activeData.indexOf('impressions') !== -1 }"
            @click="toggleData('impressions')"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="formattedClicksImpressions(currentImpressions, true)"
              placement="bottom"
              :disabled="!isTruncated(currentImpressions)"
            >
              <div
                class="number"
                v-html="formattedClicksImpressions(currentImpressions)"
              ></div>
            </el-tooltip>
            <div class="label text-info">
              {{ $root.windowWidth > 768 ? "Impressions" : "Imp" }}
            </div>
          </div>
          <div
            class="ctr"
            :class="{ active: activeData.indexOf('ctr') !== -1 }"
            @click="toggleData('ctr')"
          >
            <div class="number" v-html="formattedCtr(currentCtr)"></div>
            <div class="label text-warning">CTR</div>
          </div>
          <div
            class="conversions"
            :class="{ active: activeData.indexOf('conversions') !== -1 }"
            @click="toggleData('conversions')"
            v-if="conversionSetting && conversionSetting.conversions"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="formattedClicksImpressions(currentConversions, true)"
              placement="bottom"
              :disabled="!isTruncated(currentConversions)"
            >
              <div
                class="number"
                v-html="formattedClicksImpressions(currentConversions)"
              ></div>
            </el-tooltip>
            <div class="label text-danger text-truncate">
              {{
                $root.windowWidth > 768
                  ? conversionSetting.label
                  : conversionSetting.abbreviation
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<style lang="scss">
.text-purple {
  color: rgb(153, 50, 204);
}

.tracker-graph {
  margin-bottom: 1rem;

  .el-card__body {
    padding: 0;
  }

  .tracker-graph-container {
    display: flex;
    flex-direction: column;

    .graph-container {
      display: flex;
      flex-direction: column-reverse;

      .graph {
        margin: 1rem 0 0.5rem;
        position: relative;
        touch-action: none;

        .no-data {
          position: absolute;
          z-index: 10;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.1);
          color: #222;
          justify-content: center;
          align-items: center;
          display: none;
          font-size: 1.25rem;
          line-height: 1rem;
          text-align: center;
        }

        .no-data.visible {
          display: flex;
        }
      }

      .currentDate {
        position: absolute;
        left: 0;
        display: none;
        transform: translate(-50%, -50%);
        font-size: 0.8rem;
        color: #777;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .stats-container {
      margin-bottom: 0.5rem;

      .stats {
        display: flex;
        flex-grow: 1;

        .impressions,
        .clicks,
        .ctr,
        .conversions,
        .cr {
          display: block;
          justify-content: center;
          border-right: 1px solid #ccc;
          cursor: pointer;
          flex: 1;

          .number {
            font-size: 2rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin: 0 0.5rem;
            transition: background-color 0.3s ease;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            .unit {
              font-size: 1rem;
              margin-bottom: 0.175rem;
            }
          }

          .label {
            font-size: 1.25rem;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin: 0 0.5rem;
            transition: background-color 0.3s ease;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-bottom: 0.5rem;
          }
        }

        .impressions.active {
          .number,
          .label {
            background-color: rgba(64, 158, 255, 0.1);
          }
        }

        .clicks.active {
          .number,
          .label {
            background-color: rgba(103, 194, 58, 0.1);
          }
        }

        .ctr.active {
          .number,
          .label {
            background-color: rgba(230, 162, 60, 0.1);
          }
        }

        .cr.active {
          .number,
          .label {
            background-color: rgba(153, 50, 204, 0.1);
          }
        }

        .conversions.active {
          .number,
          .label {
            background-color: rgba(245, 108, 108, 0.1);
          }
        }

        .impressions:hover {
          .number,
          .label {
            background-color: rgba(64, 158, 255, 0.2);
          }
        }

        .clicks:hover {
          .number,
          .label {
            background-color: rgba(103, 194, 58, 0.2);
          }
        }

        .ctr:hover {
          .number,
          .label {
            background-color: rgba(230, 162, 60, 0.2);
          }
        }

        .cr:hover {
          .number,
          .label {
            background-color: rgba(153, 50, 204, 0.2);
          }
        }

        .conversions:hover {
          .number,
          .label {
            background-color: rgba(245, 108, 108, 0.2);
          }
        }

        div:last-child {
          border-color: transparent;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tracker-graph {
    .tracker-graph-container {
      flex-direction: column-reverse;

      .stats-container {
        margin-bottom: 0;
        margin-top: 0.5rem;

        .stats {
          .impressions,
          .clicks,
          .ctr,
          .conversions {
            .number {
              font-size: 1.75rem;
              margin: 0;
              border-radius: 0;
              .unit {
                font-size: 1.15rem;
              }
            }

            .label {
              font-size: 1rem;
              margin: 0;
              border-radius: 0;
            }
          }
        }
      }

      .graph-container {
        flex-direction: column;
        .currentDate {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>

<script>
import { EventBus } from "@/events"
import DateTime from "@/mixins/DateTime"
import NumberFormatter from "@/mixins/NumberFormatter"
import TrackerGraphOptions from "./TrackerGraphOptions"

export default {
  name: "TrackerGraph",

  mixins: [DateTime, NumberFormatter, TrackerGraphOptions],
  components: {},

  props: {
    data: {
      required: true,
    },
    dates: {
      required: true,
    },
    scale: {
      required: true,
    },
    range: {
      required: true,
    },
    service: {
      required: true,
    },
    type: {
      required: true,
    },
    trackerInfo: {
      required: true,
    },
    loading: {
      required: true,
    },
  },
  data() {
    return {
      currentHover: null,
      activeData: ["clicks", "impressions"],
    }
  },
  computed: {
    hasLine() {
      if (!this.type && !this.service) {
        return true
      } else {
        if (this.type === "mail") {
          return false
        } else {
          return true
        }
      }
    },
    hasBar() {
      if (!this.type && !this.service) {
        return true
      } else {
        if (this.type === "mail") {
          return true
        } else {
          return false
        }
      }
    },
    graphData() {
      if (!this.data) return

      let data = {
        bar: {
          impressions: [],
          clicks: [],
          conversions: [],
          ctr: [],
          cr: [],
        },
        line: {
          impressions: [],
          clicks: [],
          conversions: [],
          ctr: [],
          cr: [],
        },
      }

      this.$_.each(this.dates, (value, key) => {
        data["bar"]["impressions"][key] = data["line"]["impressions"][key] = 0
        data["bar"]["clicks"][key] = data["line"]["clicks"][key] = 0
        data["bar"]["conversions"][key] = data["line"]["conversions"][key] = 0
        data["bar"]["ctr"][key] = data["line"]["ctr"][key] = 0
        data["bar"]["cr"][key] = data["line"]["cr"][key] = 0
      })

      this.$_.each(this.data, (item) => {
        let type = item["graph_type"]

        this.$_.each(this.dates, (value, key) => {
          data[type]["impressions"][key] += item.data["impressions"][key]
          data[type]["clicks"][key] += item.data["clicks"][key]
          data[type]["conversions"][key] += item.data["conversions"][key]
        })
      })

      this.$_.each(this.dates, (value, key) => {
        if (data["line"]["impressions"][key] !== 0) {
          data["line"]["ctr"][key] =
            (data["line"]["clicks"][key] / data["line"]["impressions"][key]) *
            100
          data["line"]["cr"][key] =
            (data["line"]["conversions"][key] /
              data["line"]["impressions"][key]) *
            100
        }
        if (data["bar"]["impressions"][key] !== 0) {
          data["bar"]["ctr"][key] =
            (data["bar"]["clicks"][key] / data["bar"]["impressions"][key]) * 100
          data["bar"]["cr"][key] =
            (data["bar"]["conversions"][key] /
              data["bar"]["impressions"][key]) *
            100
        }
      })

      return data
    },
    totalData() {
      let data = {
        impressions: [],
        clicks: [],
        conversions: [],
        ctr: [],
        cr: [],
      }

      if (!this.data) return data

      this.$_.each(this.dates, (value, key) => {
        data["impressions"][key] =
          data["clicks"][key] =
          data["conversions"][key] =
          data["ctr"][key] =
          data["cr"][key] =
            0
      })

      this.$_.each(this.data, (item) => {
        this.$_.each(this.dates, (value, key) => {
          if (typeof data["impressions"][key] === "undefined") {
            data["impressions"][key] = 0
            data["clicks"][key] = 0
            data["conversions"][key] = 0
          }
          data["impressions"][key] += item.data["impressions"][key]
          data["clicks"][key] += item.data["clicks"][key]
          if (!this.type && !this.service) {
            if (
              item["data_type"] === "display" ||
              item["data_type"] === "sem"
            ) {
              data["conversions"][key] += item.data["conversions"][key]
            }
          } else {
            data["conversions"][key] += item.data["conversions"][key]
          }
        })
      })

      this.$_.each(this.dates, (value, key) => {
        if (data["impressions"][key] !== 0) {
          data["ctr"][key] =
            (data["clicks"][key] / data["impressions"][key]) * 100
        }

        if (data["impressions"][key] !== 0) {
          data["cr"][key] =
            (data["conversions"][key] / data["impressions"][key]) * 100
        }
      })

      return data
    },
    currentClicks() {
      return this.currentHover === null
        ? this.totalClicks
        : this.totalData.clicks[this.currentHover]
    },
    totalClicks() {
      if (!this.totalData || !this.totalData.clicks.length) return 0
      return this.totalData.clicks.reduce((a, c) => a + c)
    },
    currentImpressions() {
      return this.currentHover === null
        ? this.totalImpressions
        : this.totalData.impressions[this.currentHover]
    },
    totalImpressions() {
      if (!this.totalData || !this.totalData.impressions.length) return 0
      return this.totalData.impressions.reduce((a, c) => a + c)
    },
    currentConversions() {
      return this.currentHover === null
        ? this.totalConversions
        : this.totalData.conversions[this.currentHover]
    },
    totalConversions() {
      if (!this.totalData || !this.totalData.conversions.length) return 0
      return this.totalData.conversions.reduce((a, c) => a + c)
    },
    currentCtr() {
      return this.currentHover === null
        ? this.averageCtr
        : this.totalData.ctr[this.currentHover]
    },
    currentCr() {
      return this.currentHover === null
        ? this.averageCr
        : this.totalData.cr[this.currentHover]
    },
    averageCtr() {
      if (!this.totalImpressions) return 0
      return (this.totalClicks / this.totalImpressions) * 100
    },
    averageCr() {
      if (!this.totalImpressions) return 0
      return (this.totalConversions / this.totalImpressions) * 100
    },
    currentDate() {
      if (this.currentHover === null) return ""
      if (this.scale === "monthly" || this.scale === "weekly") {
        let start = this.$moment(this.range[0]),
          end = this.$moment(this.range[1])

        if (end.diff(start, "months") >= 6 && start.year() < end.year()) {
          if (this.scale === "monthly") {
            return this.dateTimeFormatString(
              this.dates[this.currentHover],
              "MMM 'YY",
              "YYYY-MM-DD HH:mm:ss"
            )
          } else {
            return (
              "Week of " +
              this.dateTimeFormatString(
                this.dates[this.currentHover],
                "M/DD/YY",
                "YYYY-MM-DD HH:mm:ss"
              )
            )
          }
        } else {
          if (this.scale === "monthly") {
            return this.dateTimeFormatString(
              this.dates[this.currentHover],
              "MMM",
              "YYYY-MM-DD HH:mm:ss"
            )
          } else {
            return (
              "Week of " +
              this.dateTimeFormatString(
                this.dates[this.currentHover],
                "M/DD",
                "YYYY-MM-DD HH:mm:ss"
              )
            )
          }
        }
      } else if (this.scale === "daily") {
        return this.dateTimeFormatString(
          this.dates[this.currentHover],
          "MMM Do",
          "YYYY-MM-DD HH:mm:ss"
        )
      } else {
        return this.dateTimeFormatString(
          this.dates[this.currentHover],
          "M/D hA",
          "YYYY-MM-DD HH:mm:ss"
        ).replace("M", "")
      }
    },
    noData() {
      return (
        this.totalClicks +
          this.totalImpressions +
          this.totalConversions +
          this.averageCtr ===
          0 && !this.loading
      )
    },
    emptySet() {
      return this.$_.map(this.dates, function () {
        return 0
      })
    },
    conversionSetting() {
      let key = false
      if (this.type && !this.service) {
        key = this.type
      }

      if (this.type && this.service) {
        key = this.service + "-" + this.type
        key = key.replace("remarketing-", "")
      }

      if (key) {
        return {
          conversions: this.trackerInfo.services[key]?.conversions,
          label: this.trackerInfo.services[key]?.conversions_label
            ? this.trackerInfo.services[key]?.conversions_label
            : "Conversions",
          abbreviation: this.trackerInfo.services[key]?.conversions_abbreviation
            ? this.trackerInfo.services[key]?.conversions_abbreviation
            : "Conv",
        }
      }

      return {
        conversions: this.trackerInfo.conversions,
        label: this.trackerInfo.conversions_label
          ? this.trackerInfo.conversions_label
          : "Conversions",
        abbreviation: this.trackerInfo.conversions_abbreviation
          ? this.trackerInfo.conversions_label
          : "Conv",
      }
    },
  },
  watch: {},

  methods: {
    formattedClicksImpressions(value, simple = false) {
      if (simple) return Number(value).toLocaleString()
      let formatted = this.formatClicksImpressions(value)

      return `${formatted.value}<span class="unit">${formatted.suffix}</span>`
    },
    formattedCtr(value) {
      let formatted = this.formatCtr(value)
      return `${formatted}<span class="unit">%</span>`
    },
    toggleData(type) {
      if (this.activeData.indexOf(type) === -1) {
        if (this.activeData.length > 1) {
          this.activeData.shift()
        }
        this.activeData.push(type)
      } else {
        if (this.activeData.length > 1) {
          this.activeData.splice(this.activeData.indexOf(type), 1)
        }
      }
    },
    disableScroll() {
      if (window.addEventListener)
        window.addEventListener("DOMMouseScroll", this.preventDefault, false)
      window.onwheel = this.preventDefault
      window.onmousewheel = document.onmousewheel = this.preventDefault
      window.ontouchmove = this.preventDefault
    },
    enableScroll() {
      if (window.removeEventListener)
        window.removeEventListener("DOMMouseScroll", this.preventDefault, false)
      window.onmousewheel = document.onmousewheel = null
      window.onwheel = null
      window.ontouchmove = null
    },
    preventDefault(e) {
      e = e || window.event
      if (e.preventDefault) e.preventDefault()
      e.returnValue = false
    },
    touchEndEvent() {
      this.currentHover = null
      this.$refs.chart.chart.pointer.reset()
    },
  },

  created() {},
  mounted() {
    EventBus.$on("graph-hover-change", (val) => {
      if (
        this.$refs.chart &&
        val.series.chart.container.id === this.$refs.chart.chart.container.id
      ) {
        let chartXY = this.$refs.chart.chart.container.getBoundingClientRect()
        this.$refs.currentDateContainer.style.left =
          chartXY.left + val.plotX + this.$refs.chart.chart.plotLeft + "px"
        this.$refs.currentDateContainer.style.display = "block"
        this.disableScroll()
        this.currentHover = val.x
      }
    })

    EventBus.$on("graph-hover-out", (val) => {
      if (
        this.$refs.chart &&
        val.chart.container.id === this.$refs.chart.chart.container.id
      ) {
        this.currentHover = null
        this.enableScroll()
        this.$refs.currentDateContainer.style.display = "none"
      }
    })

    window.addEventListener("touchend", this.touchEndEvent, { passive: true })

    if (this.type === "video" || this.type === "tv")
      this.activeData = ["impressions", "conversions"]
  },

  beforeDestroy() {
    window.removeEventListener("touchend", this.touchEndEvent, {
      passive: true,
    })
  },
}
</script>
