<template>
  <el-card class="stats-card">
    <router-link
      :to="link ? link : ''"
      class="tracker-link"
      :class="{ 'is-disabled': !link }"
    >
      <el-row class="stats-header">
        <div class="left text-truncate">
          <div class="icon">
            <img
              v-if="!service && !type"
              src="@/assets/images/cometads-logo.png"
              class="stats-header-image"
            />
            <img
              v-else-if="!service && type"
              :src="getServiceLogo(data.key, true)"
              class="stats-header-image"
            />
            <font-awesome-icon
              v-else-if="service && type && data.data_type !== 'mail'"
              icon="circle"
              style="margin-right: 0.5rem"
              :class="{ success: data.active, danger: !data.active }"
              class="stats-header-icon"
            ></font-awesome-icon>
          </div>
          <div class="info">
            {{ data.custom_name ? data.custom_name : data.name }}
            <div class="date-range" v-if="data.start">
              <span v-if="data.data_type === 'mail'">
                On
                <strong>{{ dateTimeFormatString(data.start) }}</strong>
                <span v-if="data.from">
                  from
                  <strong>{{ data.from }}</strong>
                </span>
              </span>
              <span v-else>
                <strong>{{ dateTimeFormatString(data.start) }}</strong> to
                <strong>{{
                  data.end ? dateTimeFormatString(data.end) : "current"
                }}</strong>
              </span>
            </div>
          </div>
        </div>
        <div class="right" v-if="link">
          <font-awesome-icon icon="arrow-right" fixed-width></font-awesome-icon>
        </div>
        <a
          :href="data.asset.uri"
          class="right stats-header-asset"
          v-else-if="data.asset"
          :id="'lightbox-' + _uid"
        >
          <img :src="data.asset.uri" />
        </a>
      </el-row>
    </router-link>
    <el-row class="stats-container">
      <el-col v-if="showGraph" :sm="8">
        <tracker-spark-line
          :clicks="data.data.clicks"
          :impressions="data.data.impressions"
          :ctr="data.data.ctr"
          :conversions="data.data.conversions"
          :cr="data.data.cr"
          :metric="current"
          :scale="scale"
        ></tracker-spark-line>
      </el-col>
      <el-col :sm="showGraph ? 16 : 24" class="stats">
        <div
          class="stat"
          v-for="metric in availableMetrics"
          :key="metric.key"
          :class="{ active: current === metric.key }"
          @click="current = metric.key"
        >
          <div
            class="number"
            :style="{
              'background-color':
                showGraph && current === metric.key
                  ? 'rgba(' + metric.color + ', .2)'
                  : 'transparent',
            }"
            v-html="getMetric(metric)"
          ></div>
          <div
            class="label"
            :style="{
              'background-color':
                showGraph && current === metric.key
                  ? 'rgba(' + metric.color + ', .2)'
                  : 'transparent',
              color: 'rgb(' + metric.color + ')',
            }"
          >
            {{ $root.windowWidth > 768 ? metric.name : metric.abbr }}
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss">
.stats-card {
  margin-bottom: 1rem;

  .el-card__body {
    padding: 0;
  }

  .tracker-link.is-disabled {
    pointer-events: none;
  }

  .stats-header {
    border-bottom: 3px solid #fafafa;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    display: flex;
    align-items: center;
    color: #303133;

    a {
      color: initial;
    }

    .stats-header-asset {
      margin-right: 0.5rem;
      cursor: pointer;
      -webkit-box-shadow: -8px 0px 5px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: -8px 0px 5px 0px rgba(255, 255, 255, 1);
      box-shadow: -8px 0px 5px 0px rgba(255, 255, 255, 1);
      pointer-events: all;
      img {
        height: 40px;
        width: auto;
      }
    }

    .left {
      flex: 1;
      display: flex;
      align-items: center;

      .icon {
        flex: 0;
      }

      .info {
        flex: 0;

        .date-range {
          font-size: 0.8rem;
          color: #999;
        }
      }

      .stats-header-image {
        display: block;
        height: 25px;
        width: auto;
        margin-right: 0.5rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .stats-header {
      font-size: 1.125rem;
    }
  }

  .stats-container {
    display: block;
    align-items: center;
    padding: 0.5rem;

    .stats {
      display: flex;
      flex-grow: 1;
      background: #fff;
      z-index: 10;
      flex-wrap: wrap;
      justify-content: center;

      .stat {
        display: block;
        justify-content: center;
        flex: 1;
        border-right: 1px solid #ccc;
        margin-right: -1px;
        cursor: pointer;

        .number {
          font-size: 2rem;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          margin: 0 0.5rem;
          transition: background-color 0.3s ease;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;

          .unit {
            font-size: 1rem;
            margin-bottom: 0.175rem;
          }
        }

        .label {
          font-size: 1.25rem;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin: 0 0.5rem;
          transition: background-color 0.3s ease;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          padding-bottom: 0.5rem;
        }
      }

      div:last-child {
        border-color: transparent;
      }
    }

    .sm-sparkline-container {
      display: flex;
      margin-bottom: 0.5rem;

      .sm-sparkline {
        max-width: calc(100% - 120px);
        flex: 1;
      }

      .sm-asset {
        flex: 0;
        background: #fff;
        z-index: 10;

        .sm-asset-container {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          img {
            width: 100px;
            height: auto;
            max-height: 100px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            margin-right: 5px;
          }
        }
      }
    }

    .lg-asset {
      .lg-asset-container {
        text-align: center;
        border-left: 1px solid #ccc;
        cursor: pointer;

        img {
          width: auto;
          height: 100%;
          max-height: 100px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .stats-card {
    .stats-container {
      padding: 0.5rem 0;
      .stats {
        .impressions,
        .clicks,
        .ctr,
        .conversions {
          .number {
            font-size: 1.75rem;
            border-radius: 0;
            margin: 0;
            .unit {
              font-size: 1.15rem;
              margin-bottom: 0.25rem;
            }
          }

          .label {
            font-size: 1rem;
            border-radius: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script>
import TrackerSparkLine from "@/components/Charts/TrackerSparkLine"
import NumberFormatter from "@/mixins/NumberFormatter"
import DateTime from "@/mixins/DateTime"
import { Luminous } from "luminous-lightbox"
import TrackerServiceLogos from "@/mixins/TrackerServiceLogos"

export default {
  name: "TrackerStatsCard",

  mixins: [NumberFormatter, DateTime, TrackerServiceLogos],
  components: {
    TrackerSparkLine,
  },

  props: {
    data: {
      required: true,
    },
    scale: {
      required: true,
    },
    range: {
      required: true,
    },
    type: {
      required: true,
    },
    service: {
      required: true,
    },
    trackerInfo: {
      required: true,
    },
  },
  data() {
    return {
      current: "clicks",
      lightbox: null,
    }
  },
  computed: {
    showGraph() {
      if (!this.data) return false
      return this.data.graph_type === "line"
    },
    metrics() {
      return {
        clicks: {
          name: "Clicks",
          abbr: "Clicks",
          key: "clicks",
          color: "103, 194, 58",
          formatter: this.formatInteger,
        },
        impressions: {
          name: "Impressions",
          abbr: "Imp",
          key: "impressions",
          color: "64, 158, 255",
          formatter: this.formatInteger,
        },
        records: {
          name: "Records",
          abbr: "Records",
          key: "impressions",
          color: "64, 158, 255",
          formatter: this.formatInteger,
        },
        ctr: {
          name: "CTR",
          abbr: "CTR",
          key: "ctr",
          color: "230, 162, 60",
          formatter: this.formatPercent,
        },
        conversions: {
          name: this.data.conversions_label
            ? this.data.conversions_label
            : "Conversions",
          abbr: this.data.conversions_abbreviation
            ? this.data.conversions_abbreviation
            : "Conv",
          key: "conversions",
          color: "245, 108, 108",
          formatter: this.formatInteger,
        },
        opens: {
          name: "Opens",
          abbr: "Opens",
          key: "opens",
          color: "245, 108, 108",
          formatter: this.formatInteger,
        },
        ctor: {
          name: "CTOR",
          abbr: "CTOR",
          key: "ctor",
          color: "153, 50, 204",
          formatter: this.formatPercent,
        },
        or: {
          name: "Open Rate",
          abbr: "OR",
          key: "or",
          color: "106, 60, 26",
          formatter: this.formatPercent,
        },
        views: {
          name: "Views",
          abbr: "Views",
          key: "impressions",
          color: "64, 158, 255",
          formatter: this.formatInteger,
        },
        completed: {
          name: "Completed",
          abbr: "Comp.",
          key: "conversions",
          color: "245, 108, 108",
          formatter: this.formatInteger,
        },
        cr: {
          name: "Complete Rate",
          abbr: "CR",
          key: "cr",
          color: "153, 50, 204",
          formatter: this.formatPercent,
        },
      }
    },
    availableMetrics() {
      if (this.data.data_type === "mail") {
        return [
          this.metrics.clicks,
          this.metrics.records,
          this.metrics.ctr,
          this.metrics.opens,
          this.metrics.ctor,
          this.metrics.or,
        ]
      } else if (
        this.data.data_type === "video" ||
        this.data.data_type === "tv"
      ) {
        if (this.data.key === "settop-tv" || this.service === "settop") {
          return [this.metrics.views, this.metrics.completed, this.metrics.cr]
        } else {
          return [
            this.metrics.views,
            this.metrics.completed,
            this.metrics.cr,
            this.metrics.clicks,
            this.metrics.ctr,
          ]
        }
      } else {
        if (this.data.conversions) {
          return [
            this.metrics.clicks,
            this.metrics.impressions,
            this.metrics.ctr,
            this.metrics.conversions,
          ]
        } else {
          return [
            this.metrics.clicks,
            this.metrics.impressions,
            this.metrics.ctr,
          ]
        }
      }
    },
    clicks() {
      let num = 0
      if (
        this.data &&
        this.data.data.clicks &&
        this.data.data.clicks.length !== 0
      ) {
        num = this.data.data.clicks.reduce((a, c) => a + c)
      }
      return num
    },
    impressions() {
      let num = 0
      if (
        this.data &&
        this.data.data.impressions &&
        this.data.data.impressions.length !== 0
      ) {
        num = this.data.data.impressions.reduce((a, c) => a + c)
      }
      return num
    },
    ctr() {
      let num = 0
      if (this.data && this.impressions !== 0) {
        num = (this.clicks / this.impressions) * 100
      }
      return num
    },
    conversions() {
      let num = 0
      if (
        this.data &&
        this.data.data.conversions &&
        this.data.data.conversions.length !== 0
      ) {
        num = this.data.data.conversions.reduce((a, c) => a + c)
      }
      return num
    },
    opens() {
      let num = 0
      if (
        this.data &&
        this.data.data.opens &&
        this.data.data.opens.length !== 0
      ) {
        num = this.data.data.opens.reduce((a, c) => a + c)
      }
      return num
    },
    ctor() {
      let num = 0
      if (this.data && this.opens !== 0) {
        num = (this.clicks / this.opens) * 100
      }
      return num
    },
    or() {
      let num = 0
      if (this.data && this.impressions !== 0) {
        num = (this.opens / this.impressions) * 100
      }
      return num
    },
    cr() {
      let num = 0
      if (this.data && this.impressions !== 0) {
        num = (this.conversions / this.impressions) * 100
      }
      return num
    },
    totalImpressions() {
      if (!this.data || !this.data.data.impressions.length) return 0
      return this.data.data.impressions.reduce((a, c) => a + c)
    },
    totalConversions() {
      if (!this.data || !this.data.data.conversions.length) return 0
      return this.data.data.conversions.reduce((a, c) => a + c)
    },
    averageCtr() {
      if (!this.data || !this.data.data.ctr.length) return 0
      return (this.totalClicks / this.totalImpressions) * 100
    },
    averageCr() {
      if (!this.data || !this.data.data.ctr.length) return 0
      return (this.totalConversions / this.totalImpressions) * 100
    },
    link() {
      if (!this.$route.params.type) {
        return {
          name: "tracker",
          params: {
            company: this.$route.params.company,
            type: this.data.key,
          },
          query: {
            start: this.range[0],
            end: this.range[1],
            scale: this.scale,
          },
        }
      } else if (this.$route.params.type && !this.$route.params.service) {
        let service = ""
        if (this.data.key.indexOf("google") !== -1) {
          service = "google"
        } else if (this.data.key.indexOf("bing") !== -1) {
          service = "bing"
        } else if (this.data.key.indexOf("facebook") !== -1) {
          service = "facebook"
        } else if (this.data.key.indexOf("siteimpact") !== -1) {
          service = "siteimpact"
        } else if (this.data.key.indexOf("mailchimp") !== -1) {
          service = "houselist"
        } else if (this.data.key.indexOf("simplifi") !== -1) {
          service = "geofencing"
        } else if (this.data.key.indexOf("tremor") !== -1) {
          service = "streaming"
        } else if (this.data.key.indexOf("settop") !== -1) {
          service = "settop"
        } else if (this.data.key.indexOf("constantcontact") !== -1) {
          service = "constantcontact"
        } else if (this.data.key.indexOf("emailresultz") !== -1) {
          service = "leasedlist"
        } else if (this.data.key.indexOf("agility") !== -1) {
          service = "agility"
        }
        if (this.data.key.indexOf("remarketing") !== -1) {
          service += "-remarketing"
        }
        return {
          name: "tracker",
          params: {
            company: this.$route.params.company,
            type: this.$route.params.type,
            service: service,
          },
          query: {
            start: this.range[0],
            end: this.range[1],
            scale: this.scale,
          },
        }
      } else {
        return false
      }
    },
  },
  watch: {},

  methods: {
    formatInteger(value) {
      let formatted = this.formatClicksImpressions(value)

      return `${formatted.value}<span class="unit">${formatted.suffix}</span>`
    },
    formatPercent(value) {
      let formatted = this.formatCtr(value)
      return `${formatted}<span class="unit">%</span>`
    },
    getMetric(metric) {
      return metric.formatter(this[metric.key])
    },
    formattedClicksImpressions(value) {
      let formatted = this.formatClicksImpressions(value)

      return `${formatted.value}<span class="unit">${formatted.suffix}</span>`
    },
    formattedCtr(value) {
      let formatted = this.formatCtr(value)
      return `${formatted}<span class="unit">%</span>`
    },
  },

  mounted() {
    if (this.data.asset)
      this.lightbox = new Luminous(
        document.getElementById("lightbox-" + this._uid)
      )
    if (this.data.data_type === "video" || this.data.data_type === "tv")
      this.current = "impressions"
  },
  beforeDestroy() {
    if (this.data.asset) this.lightbox.destroy()
  },
}
</script>
