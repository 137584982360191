<script>
import { EventBus } from "@/events"

let formatYLabel = function (val) {
  if (val >= 1000000) {
    val = val / 100000
    return val % 1 === 0 ? val + "M" : val.toFixed(1) + "M"
  } else if (val >= 1000) {
    val = val / 1000
    return val % 1 === 0 ? val + "K" : val.toFixed(1) + "K"
  } else {
    return val.toLocaleString()
  }
}

export default {
  name: "TrackerGraphOptions",

  computed: {
    options() {
      return {
        chart: {
          height: "33%",
          // margin: 0,
          spacingLeft: 0,
          spacingRight: 0,
          style: {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            fontColor: "#303133",
          },
        },

        credits: {
          enabled: false,
        },

        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
                select: {
                  enabled: false,
                },
              },
            },
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            point: {
              events: {
                mouseOver: function () {
                  EventBus.$emit("graph-hover-change", this)
                },
              },
            },
            events: {
              mouseOut: function () {
                EventBus.$emit("graph-hover-out", this)
              },
            },
          },
        },

        series: [
          {
            type: "line",
            color: "#67c23a",
            id: "clicks",
            yAxis: "clicks",
            name: "Clicks",
            data: this.graphData.line.clicks,
            visible: this.activeData.indexOf("clicks") !== -1 && this.hasLine,
            zIndex: 2,
          },
          {
            type: "line",
            color: "#409eff",
            id: "impressions",
            yAxis: "impressions",
            name: "Impressions",
            data: this.graphData.line.impressions,
            visible:
              this.activeData.indexOf("impressions") !== -1 && this.hasLine,
            zIndex: 2,
          },
          {
            type: "line",
            color: "#e6a23c",
            id: "ctr",
            yAxis: "ctr",
            name: "Click Through Rate",
            data: this.graphData.line.ctr,
            visible: this.activeData.indexOf("ctr") !== -1 && this.hasLine,
            zIndex: 2,
          },
          {
            type: "line",
            color: "#f56c6c",
            id: "conversions",
            yAxis: "conversions",
            name: "Conversions",
            data: this.graphData.line.conversions,
            visible:
              this.activeData.indexOf("conversions") !== -1 && this.hasLine,
            zIndex: 2,
          },
          {
            type: "line",
            color: "#9932cc",
            id: "cr",
            yAxis: "cr",
            name: "Complete Rate",
            data: this.graphData.line.cr,
            visible: this.activeData.indexOf("cr") !== -1 && this.hasLine,
            zIndex: 2,
          },
          {
            type: "line", // This series made of all zeros will allow the graph hover to be triggered on
            color: "transparent", // points that do not otherwise have column data.
            id: "empty",
            yAxis: "empty",
            name: "empty",
            data: this.emptySet,
            visible: this.hasBar,
            zIndex: 1,
          },
          {
            type: "column",
            color: !this.type && !this.service ? "#53992e" : "#67c23a",
            id: "clicks",
            yAxis: "clicks",
            name: "Clicks",
            data: this.graphData.bar.clicks,
            visible: this.activeData.indexOf("clicks") !== -1 && this.hasBar,
            zIndex: 1,
          },
          {
            type: "column",
            color: !this.type && !this.service ? "#3889d9" : "#409eff",
            id: "impressions",
            yAxis: "impressions",
            name: "Impressions",
            data: this.graphData.bar.impressions,
            visible:
              this.activeData.indexOf("impressions") !== -1 && this.hasBar,
            zIndex: 1,
          },
          {
            type: "column",
            color: !this.type && !this.service ? "#bf8734" : "#e6a23c",
            id: "ctr",
            yAxis: "ctr",
            name: "Click Through Rate",
            data: this.graphData.bar.ctr,
            visible: this.activeData.indexOf("ctr") !== -1 && this.hasBar,
            zIndex: 1,
          },
          {
            type: "column",
            color: !this.type && !this.service ? "#cf5d5d" : "#f56c6c",
            id: "conversions",
            yAxis: "conversions",
            name: "Conversions",
            data: this.graphData.bar.conversions,
            visible:
              this.activeData.indexOf("conversions") !== -1 && this.hasBar,
            zIndex: 1,
          },
          {
            type: "column",
            color: !this.type && !this.service ? "#5c1e7b" : "#9932cc",
            id: "cr",
            yAxis: "cr",
            name: "Complete Rate",
            data: this.graphData.bar.conversions,
            visible: this.activeData.indexOf("cr") !== -1 && this.hasBar,
            zIndex: 1,
          },
        ],

        title: {
          text: null,
        },

        tooltip: {
          crosshairs: true,
          shared: true,
          enabled: false,
        },

        xAxis: {
          categories: this.dates,
          labels: {
            enabled: false,
          },
          tickLength: 0,
        },

        yAxis: [
          {
            id: "clicks",
            title: {
              text: "Clicks",
              margin: 10,
              style: {
                color: "#53992e",
                textOverflow: "ellipsis",
                fontWeight: "600",
                letterSpacing: ".1rem",
              },
            },
            labels: {
              formatter: function () {
                return formatYLabel(this.value)
              },
              padding: 0,
            },
            offset: -15,
            visible: this.activeData.indexOf("clicks") !== -1,
          },
          {
            id: "impressions",
            title: {
              text: "Impressions",
              margin: 10,
              style: {
                color: "#3889d9",
                textOverflow: "ellipsis",
                fontWeight: "600",
                letterSpacing: ".1rem",
              },
            },
            labels: {
              formatter: function () {
                return formatYLabel(this.value)
              },
            },
            offset: -15,
            opposite:
              this.activeData.length !== 1 &&
              this.activeData.indexOf("clicks") !== -1,
            visible: this.activeData.indexOf("impressions") !== -1,
          },
          {
            id: "ctr",
            title: {
              text: "CTR",
              margin: 10,
              style: {
                color: "#e6a23c",
                textOverflow: "ellipsis",
                fontWeight: "600",
                letterSpacing: ".1rem",
              },
            },
            labels: {
              formatter: function () {
                return this.value + "%"
              },
            },
            offset: -15,
            opposite:
              this.activeData.length !== 1 &&
              (this.activeData.indexOf("clicks") !== -1 ||
                this.activeData.indexOf("impressions") !== -1),
            visible: this.activeData.indexOf("ctr") !== -1,
          },
          {
            id: "conversions",
            title: {
              text:
                this.type === "tv" || this.type === "video"
                  ? "Completed"
                  : this.conversionSetting.label,
              margin: 10,
              style: {
                color: "#f56c6c",
                textOverflow: "ellipsis",
                fontWeight: "600",
                letterSpacing: ".1rem",
              },
            },
            labels: {
              formatter: function () {
                return formatYLabel(this.value)
              },
            },
            offset: -15,
            opposite:
              this.activeData.length !== 1 &&
              (this.activeData.indexOf("clicks") !== -1 ||
                this.activeData.indexOf("impressions") !== -1 ||
                this.activeData.indexOf("ctr") !== -1),
            visible: this.activeData.indexOf("conversions") !== -1,
          },
          {
            id: "cr",
            title: {
              text: "Complete Rate",
              margin: 10,
              style: {
                color: "#9932cc",
                textOverflow: "ellipsis",
                fontWeight: "600",
                letterSpacing: ".1rem",
              },
            },
            labels: {
              formatter: function () {
                return this.value + "%"
              },
            },
            offset: -15,
            opposite:
              this.activeData.length !== 1 &&
              (this.activeData.indexOf("conversions") !== -1 ||
                this.activeData.indexOf("impressions") !== -1 ||
                this.activeData.indexOf("ctr") !== -1 ||
                this.activeData.indexOf("conversions") !== -1),
            visible: this.activeData.indexOf("cr") !== -1,
          },
          {
            id: "empty", // Hidden y-axis for the empty data series. See comment above.
            title: {
              enabled: false,
            },
            margin: 0,
            labels: {
              formatter: function () {
                return ""
              },
            },
            visible: true,
          },
        ],
      }
    },
  },
}
</script>
